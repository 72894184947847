import React from 'react';
import { AppBar, Toolbar, Typography, Button, Container, Grid, TextField, Box, Link } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import HeroImage from './assets/hero.png'; // Imagem do hero
import ContalkLogo from './assets/contalk.png'; // Logo do sistema ContTalk

const theme = createTheme({
  palette: {
    primary: { main: '#ffffff' }, // Fundo branco para a barra de navegação
    secondary: { main: '#f1c40f' }, // Amarelo para botões e texto de navegação
    text: { primary: '#4a4a4a', secondary: '#6c63ff' },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    h3: { fontWeight: 700 },
    h4: { fontWeight: 600 },
    body1: { fontSize: '1rem' },
  },
});

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      {/* Header */}
      <AppBar position="static" style={{ backgroundColor: theme.palette.primary.main, boxShadow: 'none' }}>
        <Toolbar>
          <img src={ContalkLogo} alt="ContTalk" style={{ height: 50, marginRight: '10px' }} />
          <Typography variant="h6" style={{ flexGrow: 1, color: theme.palette.secondary.main }}>
            
          </Typography>
          <Button color="inherit" style={{ color: theme.palette.secondary.main }}>
            Home
          </Button>
          <Button color="inherit" style={{ color: theme.palette.secondary.main }}>
            Recursos
          </Button>
          <Button color="inherit" style={{ color: theme.palette.secondary.main }}>
            Contato
          </Button>
        </Toolbar>
      </AppBar>

      {/* Hero Section */}
      <Box
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8)), url(${HeroImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          color: 'white',
        }}
      >
        <Container maxWidth="md">
          <Typography variant="h3" gutterBottom>
            Gerencie seu escritório contábil com eficiência
          </Typography>
          <Typography variant="h6" gutterBottom>
            Sistema desenvolvido por contadores para contadores. Integrado com Domínio Sistemas e pronto para transformar sua gestão!
          </Typography>
          <Button
            variant="contained"
            style={{
              backgroundColor: theme.palette.secondary.main,
              color: '#fff',
              marginTop: '20px',
              borderRadius: '25px',
            }}
            size="large"
          >
            Experimente Agora
          </Button>
        </Container>
      </Box>

      {/* Recursos Section */}
      <Container maxWidth="lg" style={{ marginTop: '60px', marginBottom: '60px' }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" gutterBottom color="textPrimary">
              Módulo Financeiro Completo
            </Typography>
            <Typography variant="body1">
              Controle todas as finanças do seu escritório com facilidade e eficiência.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" gutterBottom color="textPrimary">
              CRM Integrado
            </Typography>
            <Typography variant="body1">
              Gerencie seus clientes e automatize processos para aumentar produtividade.
            </Typography>
          </Grid>
        </Grid>
      </Container>

      {/* Formulário de Contato */}
      <Container maxWidth="sm" style={{ marginBottom: '60px' }}>
        <Typography variant="h5" gutterBottom color="textPrimary">
          Entre em Contato
        </Typography>
        <form>
          <TextField
            fullWidth
            label="Nome"
            margin="normal"
            variant="outlined"
            InputProps={{ style: { borderColor: '#6c63ff' } }}
          />
          <TextField
            fullWidth
            label="Email"
            margin="normal"
            variant="outlined"
            InputProps={{ style: { borderColor: '#6c63ff' } }}
          />
          <TextField
            fullWidth
            label="Telefone"
            margin="normal"
            variant="outlined"
            InputProps={{ style: { borderColor: '#6c63ff' } }}
          />
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            fullWidth
            style={{ marginTop: '20px', borderRadius: '25px' }}
          >
            Enviar
          </Button>
        </form>
      </Container>

      {/* Footer */}
      <Box
        style={{
          backgroundColor: '#282c34',
          padding: '20px',
          color: 'white',
          textAlign: 'center',
        }}
      >
        <Typography variant="body2">
          © 2024 ContTalk. Todos os direitos reservados.
        </Typography>
        <Typography variant="body2">
          Rua Exemplo, 123 - São Paulo, SP |{' '}
          <Link href="#" style={{ color: theme.palette.secondary.main }}>
            Ver no Google Maps
          </Link>
        </Typography>
      </Box>
    </ThemeProvider>
  );
}
